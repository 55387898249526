@import './shared';


body {
    min-height: 100vh;

}



.page-container {


    // padding-bottom: 50px;

    .page-item {
        min-height: calc(100vh - 130px);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        margin-bottom: 15px;


        @include md {
            min-height: auto;
            padding: 0px;
            align-items: center;
            margin-top: 25px;
        }

        .left {
            flex-basis: 40%;
            padding: 20px;
            margin: 20px;
            flex-grow: 1;

     

            h3 {
                font-size: 40px;
                font-weight: bold;
                color: #005889;

                @include md {
                    font-size: 20px;
                    text-align: start;
                    margin-bottom: 15px;
                }
            }

            p {
                text-align: justify;
                margin-top: 20px;
            }

            @include md {
                flex-grow: 1;
                padding: 0px;
                margin: 0px;
                display: flex;
                align-items: center;
            }
        }

        .right {
            $widthPercentage:55%;
            flex-basis: $widthPercentage;;
            max-width:$widthPercentage;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            $dimensions: 900px;

            @include md {
                flex-basis: 100%;
                align-items: stretch;
            }

            .p-galleria {

                max-width: $dimensions;
                max-height: $dimensions ;

            }

            .p-galleria-item {

                max-width: $dimensions;
                max-height: calc($dimensions - 300px);
                object-fit: cover;
                overflow: hidden;
                background: rgba(0, 0, 0, 0.9);
            }

            @include lg {

                .p-galleria,
                .p-galleria-item {

                    max-width: 100%;

                }

            }
        }

    }
}









 .p-galleria-thumbnail-item {
    max-height: 90px;
}