*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

$primaryColor:#008098;
$navLinkColor:#005889;
// Small phones
$screen-tn-max: 450px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 596px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;


// Phone devices
@mixin tn {
    @media (max-width: #{$screen-tn-max}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}


.animated-image{
    position: relative;
    animation: fade;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}





@keyframes fade {
    from{
        transform: scale(00.8);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}

