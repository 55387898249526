@import './shared';


body{
    min-height: 100vh;
}

.App{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &>:last-child{
        margin: auto 0;
       
    }
    margin-bottom: 30px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;

}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    transition: 0.5s;
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}