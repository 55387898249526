@import './shared';
.nav-bar{
    padding: 0 15px 0 20px;
    .navbar-brand{
        display: inline-block;
     
        img{
            width: 100%;
            max-height: 50px;
        }
    }
    
    .nav-item{
        margin: 0 10px;
        cursor: pointer;
        color:$navLinkColor ;
        font-weight: 500;
        border-bottom: 1px solid transparent;
       @include lg{
        display: block;
        max-width: fit-content;
        text-align: center;
        margin: 10px auto;
       }

       &.active{
        border-bottom: 2px solid $primaryColor;
        color:$primaryColor ;
        font-weight: 700;
       }
    }
}