@import './shared';

.header{
    display: none;
    padding: 10px calc(1rem);
    margin-top: 25px;
    @include lg{
        display: flex;
        flex-flow: row wrap;
        gap: 15px;


    }

 


    button{
        $padding:5px;
        padding: $padding 4*$padding;
        border-radius: 10px;
        background: #fff;
        color: $primaryColor;
        font-size: 1rem;
        cursor: pointer;
        border: 1px solid $primaryColor;
        transition:  all 0.5s ease-in-out;
        &.active{
            background: $primaryColor;
            color: #fff;

        }
    }
}
